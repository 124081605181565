export default {
  "account": {
    "id": 1,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticationTextStep1": {
    "id": 2,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep1"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Open your Authenticator app"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep1"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticationTextStep2": {
    "id": 3,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep2"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Choose Add"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep2"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticationTextStep3": {
    "id": 4,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep3"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Choose 'Scan barcode' option"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep3"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticationTextStep4": {
    "id": 5,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep4"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Scan barcode"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep4"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticationTextStep5": {
    "id": 6,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep5"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Enter code displayed in the authenticator"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep5"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticationTextStep6": {
    "id": 7,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep6"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Click validate button"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticationTextStep6"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "takeTheFollowingSteps": {
    "id": 8,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["takeTheFollowingSteps"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAKE THE FOLLOWING STEPS :"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["takeTheFollowingSteps"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "connectAuthenticator": {
    "id": 9,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connectAuthenticator"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Authenticator"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connectAuthenticator"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticatorSmall": {
    "id": 10,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticatorSmall"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticatorSmall"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "cancel": {
    "id": 11,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "validate": {
    "id": 12,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validate"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validate"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "documentUpperCase": {
    "id": 13,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentUpperCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOCUMENT"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentUpperCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/documentation/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "editAccount": {
    "id": 14,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editAccount"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Account"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editAccount"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "save": {
    "id": 15,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockExtractionMethods": {
    "id": 16,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtractionMethods"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Extraction Methods"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtractionMethods"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockExtractionMethodsText1": {
    "id": 17,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtractionMethodsText1"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key extraction always requires an additional authentication step. It is preferable to activate authentication via an authenticator app (2FA), but can also be performed via an activation link that is send to the email address of the current user."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtractionMethodsText1"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockExtractionMethodsText2": {
    "id": 18,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtractionMethodsText2"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that when 'Via email link' is selected, VaultN cannot take any responsibility of the assets anymore."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtractionMethodsText2"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockExtractionLowerCase": {
    "id": 19,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtractionLowerCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Extraction"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtractionLowerCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "uploadDocumentLowerCase": {
    "id": 20,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploadDocumentLowerCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Document"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploadDocumentLowerCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/documentation/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "uploadNewImage": {
    "id": 21,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploadNewImage"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new image"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploadNewImage"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products-add/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "users": {
    "id": 22,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockExtraction": {
    "id": 23,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtraction"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock extraction"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockExtraction"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "lastLogin": {
    "id": 24,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lastLogin"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Login"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lastLogin"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "ownerRole": {
    "id": 25,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ownerRole"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Role"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ownerRole"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "userRole": {
    "id": 26,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userRole"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Role"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userRole"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticatorConnected": {
    "id": 26,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticatorConnected"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App connected! Check your email."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticatorConnected"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "displaying": {
    "id": 27,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["displaying"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displaying"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["displaying"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/api-insights/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "duration": {
    "id": 28,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duration"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duration"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duration"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/api-insights/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "headersLowerCase": {
    "id": 29,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["headersLowerCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headers"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["headersLowerCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/api-insights/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "logEntries": {
    "id": 30,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logEntries"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log entries"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logEntries"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/api-insights/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "logs": {
    "id": 31,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logs"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logs"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logs"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/api-insights/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "requestsLowerCase": {
    "id": 32,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requestLowerCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requestLowerCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/api-insights/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "responseLowerCase": {
    "id": 33,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["responseLowerCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["responseLowerCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/api-insights/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "serviceUrl": {
    "id": 34,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serviceUrl"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service url"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["serviceUrl"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/api-insights/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "timeStamp": {
    "id": 35,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timeStamp"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Stamp"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timeStamp"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/api-insights/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "addCertificateUpperCase": {
    "id": 36,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCertificateUpperCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD CERTIFICATE"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCertificateUpperCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/certificate/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "addCertificateLowerCase": {
    "id": 37,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCertificateLowerCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Certificate"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCertificateLowerCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/certificate/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "upload": {
    "id": 38,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/certificate/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "mustBeCrtCerFiles": {
    "id": 39,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mustBeCrtCerFiles"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected files must be .crt or .cer file"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mustBeCrtCerFiles"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/certificate/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "selectCrtFileText1": {
    "id": 40,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selectCrtFileText1"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a .CER or .CRT file on your device to upload and install a certificate."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selectCrtFileText1"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/certificate/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "selectCrtFileText2": {
    "id": 41,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selectCrtFileText2"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure that the selected file meets the requirements"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selectCrtFileText2"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/certificate/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockAdded": {
    "id": 42,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockAdded"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will being updated via your inbox when the batch is fully processed."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockAdded"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/certificate/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "agreementUpperCase": {
    "id": 43,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreementUpperCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIGHT"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreementUpperCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "agreements": {
    "id": 44,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreements"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreements"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "agreementLowerCase": {
    "id": 45,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreementLowerCase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agreementLowerCase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "discontinue": {
    "id": 46,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discontinue"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinue"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discontinue"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "active": {
    "id": 47,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "archive": {
    "id": 48,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archive"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archive"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "available": {
    "id": 49,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "blacklisted": {
    "id": 50,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blacklisted"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisted"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blacklisted"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "chain": {
    "id": 51,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chain"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chain"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chain"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "extracted": {
    "id": 52,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extracted"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracted"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extracted"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "graph": {
    "id": 53,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["graph"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graph"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["graph"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "inserted": {
    "id": 54,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inserted"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserted"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inserted"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "me": {
    "id": 55,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["me"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["me"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "received": {
    "id": 56,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["received"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["received"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "transferred": {
    "id": 57,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transferred"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferred"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transferred"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "product": {
    "id": 58,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "assets": {
    "id": 59,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assets"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assets"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockPoolLabel": {
    "id": 60,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockPool"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockpool"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockPool"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "close": {
    "id": 61,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "addCertificate": {
    "id": 62,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCertificate"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Certificate"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCertificate"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/certificate/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "addUser": {
    "id": 63,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addUser"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addUser"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/users/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "addWebhook": {
    "id": 64,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addWebhook"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Webhook"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addWebhook"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/users/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "filterApply": {
    "id": 65,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filterApply"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filterApply"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "filterCancel": {
    "id": 66,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filterCancel"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filterCancel"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "clear": {
    "id": 67,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clear"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clear"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "createReport": {
    "id": 68,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createReport"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Report"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createReport"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "exportGoogleSheet": {
    "id": 69,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exportGoogleSheet"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export this list to Google Sheets"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exportGoogleSheet"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "exportListToGoogleSheets": {
    "id": 70,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exportListToGoogleSheets"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Google Sheets"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exportListToGoogleSheets"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "extract": {
    "id": 71,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extract"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extract"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extract"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "filter": {
    "id": 72,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "filters": {
    "id": 73,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filters"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filters"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "insert": {
    "id": 74,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insert"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insert"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "transfer": {
    "id": 75,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transfer"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transfer"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "viewGoogleSheet": {
    "id": 76,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viewGoogleSheet"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View this list in Google Sheets"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viewGoogleSheet"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "viewListGoogleSheets": {
    "id": 77,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viewListGoogleSheets"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Google Sheets"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viewListGoogleSheets"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "addConnection": {
    "id": 78,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addConnection"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Connection"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addConnection"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "loading": {
    "id": 79,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/transaction/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "areYouSure": {
    "id": 80,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areYouSure"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to perform this action?"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areYouSure"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/finance/promotions/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "atRow": {
    "id": 81,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atRow"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" at row "])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atRow"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/finance/price-lists/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "platformDRM": {
    "id": 82,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platformDRM"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform (DRM)"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platformDRM"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "dateFrom": {
    "id": 83,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dateFrom"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dateFrom"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "dateTill": {
    "id": 84,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dateTill"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dateTill"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "dateWarn": {
    "id": 85,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dateWarn"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has not been selected."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dateWarn"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "filterOptions": {
    "id": 86,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filterOptions"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Options"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filterOptions"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "moreFilters": {
    "id": 87,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moreFilters"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Filters"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moreFilters"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "name": {
    "id": 88,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "role": {
    "id": 89,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["role"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["role"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticated": {
    "id": 90,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticated"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticated"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticated"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "emailNotifications": {
    "id": 91,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emailNotifications"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email notifications"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emailNotifications"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "isActive": {
    "id": 92,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isActive"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isActive"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "vaultnName": {
    "id": 93,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaultnName"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VaultN"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaultnName"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "email": {
    "id": 94,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "vaultnGuid": {
    "id": 95,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaultnGuid"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VaultN GUID"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaultnGuid"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "companyName": {
    "id": 96,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["companyName"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["companyName"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "defaultCurrency": {
    "id": 97,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultCurrency"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default currency"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultCurrency"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "defaultPriceList": {
    "id": 98,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultPriceList"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default pricelist"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defaultPriceList"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "country": {
    "id": 99,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "timezone": {
    "id": 100,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timezone"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timezone"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timezone"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "accountUpdated": {
    "id": 101,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accountUpdated"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Updated"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accountUpdated"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "delete": {
    "id": 102,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "detail": {
    "id": 103,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detail"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detail"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "download": {
    "id": 104,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "edit": {
    "id": 105,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "ErrorType500": {
    "id": 106,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ErrorType500"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server encountered an unexpected error. Please try again."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ErrorType500"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "ErrorType404": {
    "id": 107,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ErrorType404"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records found."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ErrorType404"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "ErrorType401": {
    "id": 108,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ErrorType401"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication error. Please login"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ErrorType401"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "noData": {
    "id": 109,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noData"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noData"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "of": {
    "id": 110,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" of "])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "priceListIsReadyToDownload": {
    "id": 111,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceListIsReadyToDownload"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list excel file is ready to download"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceListIsReadyToDownload"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "reportIsReadyToDownload": {
    "id": 112,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reportIsReadyToDownload"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report is ready to download"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reportIsReadyToDownload"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/finance/report-list/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "reportPrepared": {
    "id": 113,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reportPrepared"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report is being prepared. This might take a while..."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reportPrepared"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/finance/report-list/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "requestSuccess": {
    "id": 114,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requestSuccess"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request successful"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requestSuccess"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "search": {
    "id": 115,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "tokenExpired": {
    "id": 116,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tokenExpired"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication expired"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tokenExpired"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "metaDataSaved": {
    "id": 117,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metaDataSaved"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata saved"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metaDataSaved"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "productSaved": {
    "id": 118,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productSaved"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product saved"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productSaved"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "productNotActive": {
    "id": 119,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productNotActive"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product is not connected. Actions are not allowed."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productNotActive"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "priceListProductRowUpdate": {
    "id": 120,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceListProductRowUpdate"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently you are editing this region. The changes you make can't be undone after SAVING."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceListProductRowUpdate"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/finance/price-lists/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "priceListAddCountry": {
    "id": 121,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceListAddCountry"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently you are adding new region. The changes you make can't be undone after SAVING."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceListAddCountry"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/finance/price-lists/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "priceListProductRowDelete": {
    "id": 122,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceListProductRowDelete"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently you are deleting region. The changes you make can't be undone."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceListProductRowDelete"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/finance/price-lists/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "alreadyAnAccount": {
    "id": 123,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alreadyAnAccount"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already a part of the VaultN community? Sign in here"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alreadyAnAccount"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "company": {
    "id": 124,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "continueAndLogin": {
    "id": 126,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continueAndLogin"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue and login"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continueAndLogin"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "createANewVault": {
    "id": 127,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createANewVault"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Your VaultN Account"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createANewVault"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "next": {
    "id": 128,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "registrationInfoText": {
    "id": 129,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrationInfoText"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email to complete your registration"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrationInfoText"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "thankYouForYourRegistration1": {
    "id": 130,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thankYouForYourRegistration1"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thankYouForYourRegistration1"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "thankYouForYourRegistration2": {
    "id": 131,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thankYouForYourRegistration2"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for your registration"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thankYouForYourRegistration2"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "vaultNName": {
    "id": 132,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaultNName"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VaultN Name"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaultNName"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "welcomeToVaultN": {
    "id": 133,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcomeToVaultN"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to VaultN! Start your journey towards smarter distribution."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcomeToVaultN"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/create-account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "results": {
    "id": 134,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "Inbox": {
    "id": 135,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/inbox/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "Outbox": {
    "id": 136,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbox"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbox"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbox"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/outbox/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockPoolIn": {
    "id": 137,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockPoolIn"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockPoolIn"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/stockpool-in/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockPoolOut": {
    "id": 138,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockPoolOut"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockPoolOut"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/stockpool-out/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "apply": {
    "id": 139,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apply"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apply"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "fromDate": {
    "id": 140,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromDate"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromDate"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "toDate": {
    "id": 141,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toDate"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toDate"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "addNewArticle": {
    "id": 142,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addNewArticle"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Article"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addNewArticle"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/documentation/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "editDocumentation": {
    "id": 143,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editDocumentation"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Documentation"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editDocumentation"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/documentation/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "checkEmail": {
    "id": 144,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkEmail"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check email"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkEmail"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "clickButtonToStartDownload": {
    "id": 145,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clickButtonToStartDownload"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the button below to start your file download"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clickButtonToStartDownload"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "extractAssets": {
    "id": 146,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extractAssets"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXTRACT INVENTORY"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extractAssets"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "extracting": {
    "id": 147,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extracting"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXTRACTING..."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extracting"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "fillCodeDisplayed": {
    "id": 148,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fillCodeDisplayed"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the code displayed in your connected Authenticator app"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fillCodeDisplayed"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "isAgreed": {
    "id": 149,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isAgreed"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm this extraction is final and cannot be undone"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["isAgreed"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "noAuthenticatorConnected": {
    "id": 150,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noAuthenticatorConnected"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Authenticator connected yet? Go to your account settings to connect your Authenticator to your account"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noAuthenticatorConnected"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "noPlatforms": {
    "id": 151,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noPlatforms"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no Platform (DRM)s related to that right"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noPlatforms"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "noStock": {
    "id": 152,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noStock"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no stock for this product and this Platform (DRM)"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noStock"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "platform": {
    "id": 153,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "quantity": {
    "id": 154,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "receivedInformation": {
    "id": 155,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receivedInformation"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have received an email containing information on how to download your assets"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receivedInformation"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "receivedSerials": {
    "id": 156,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receivedSerials"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email containing information on how to download your serials"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receivedSerials"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "region": {
    "id": 157,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "rights": {
    "id": 158,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rights"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rights"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "selectProduct": {
    "id": 159,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selectProduct"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Product"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selectProduct"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "sendAuthCodeToEmail": {
    "id": 160,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sendAuthCodeToEmail"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send me the 6-digit confirmation code to my e-mail instead"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sendAuthCodeToEmail"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authCodeSentEmail": {
    "id": 161,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authCodeSentEmail"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code is sent. Please check your email"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authCodeSentEmail"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "edition": {
    "id": 162,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edition"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edition"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "publisher": {
    "id": 163,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publisher"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publisher"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "extractionIsFinalCannotBeDone": {
    "id": 164,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extractionIsFinalCannotBeDone"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand that this extraction is final and cannot be undone"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extractionIsFinalCannotBeDone"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "pleaseCheckAuthenticator": {
    "id": 165,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseCheckAuthenticator"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check authenticator code"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseCheckAuthenticator"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "myProfile": {
    "id": 166,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myProfile"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myProfile"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "signOut": {
    "id": 167,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signOut"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signOut"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/account/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "dolar": {
    "id": 168,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dolar"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dolar"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "dolarSign": {
    "id": 169,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dolarSign"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dolarSign"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "euro": {
    "id": 170,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["euro"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["euro"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "euroSign": {
    "id": 171,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["euroSign"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["euroSign"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "myCurrency": {
    "id": 172,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myCurrency"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Currency"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myCurrency"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "welcome": {
    "id": 173,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to VaultN,"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/home/dashboard/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "createNewSku": {
    "id": 174,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createNewSku"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New SKU"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createNewSku"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "createSku": {
    "id": 175,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createSku"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATE SKU"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createSku"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "createNewSkuUppercase": {
    "id": 176,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createNewSkuUppercase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATE NEW SKU"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["createNewSkuUppercase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "confirmation": {
    "id": 177,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmation"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmation"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "skuUppercase": {
    "id": 178,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skuUppercase"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skuUppercase"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "addMoreSku": {
    "id": 179,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addMoreSku"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more SKU"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addMoreSku"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "create": {
    "id": 180,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "skuCreated": {
    "id": 181,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skuCreated"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU Created"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skuCreated"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "created": {
    "id": 182,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" created."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "settingsBtn": {
    "id": 183,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settingsBtn"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settingsBtn"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "uploads": {
    "id": 184,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploads"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploads"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploads"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "assetsInserted": {
    "id": 185,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assetsInserted"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets are being inserted. This might take a while..."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assetsInserted"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "statusCanceled": {
    "id": 186,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statusCanceled"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status canceled"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statusCanceled"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/uploads/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "authenticateLabel": {
    "id": 187,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticate"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticate"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authenticate"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "stockRepositoryUrl": {
    "id": 188,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockRepositoryUrl"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Repository URL"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockRepositoryUrl"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "priceRepositoryUrl": {
    "id": 189,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceRepositoryUrl"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Repository URL"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceRepositoryUrl"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "transactionRepositoryUrl": {
    "id": 190,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactionRepositoryUrl"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Repository URL"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transactionRepositoryUrl"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "jwtSecret": {
    "id": 191,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jwtSecret"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JWT Secret"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jwtSecret"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "endpointUrl": {
    "id": 192,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endpointUrl"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endpoint URL"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endpointUrl"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/webhooks/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "type": {
    "id": 193,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/webhooks/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "addWbhook": {
    "id": 194,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addWbhook"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Webhook"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addWbhook"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/webhooks/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "updateWebhook": {
    "id": 195,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updateWebhook"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Webhook"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updateWebhook"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/settings/webhooks/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "uploadDetails": {
    "id": 196,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploadDetails"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Details"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploadDetails"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/upload-detail/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "currency": {
    "id": 197,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "market": {
    "id": 198,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["market"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["market"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "included": {
    "id": 199,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["included"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Included"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["included"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "includedCheckbox": {
    "id": 200,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["includedCheckbox"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude this country currency"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["includedCheckbox"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "skuLabel": {
    "id": 201,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skuLabel"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skuLabel"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "inActive": {
    "id": 202,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inActive"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Active"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inActive"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "downloadErrorKeys": {
    "id": 203,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloadErrorKeys"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Error Keys"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloadErrorKeys"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/uploads-detail/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "period": {
    "id": 204,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["period"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["period"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "vault": {
    "id": 205,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vault"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vault"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vault"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "clearAll": {
    "id": 206,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clearAll"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear All"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clearAll"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "date": {
    "id": 207,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "copy": {
    "id": 208,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "productOrSku": {
    "id": 209,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productOrSku"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name or SKU"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productOrSku"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "unitPrice": {
    "id": 210,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitPrice"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Price"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitPrice"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "estimatedPrice": {
    "id": 211,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimatedPrice"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Price"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimatedPrice"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "description": {
    "id": 212,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "blacklisting": {
    "id": 213,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blacklisting"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisting"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blacklisting"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "visibility": {
    "id": 214,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visibility"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visibility"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "extraction": {
    "id": 215,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraction"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraction"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "promotionNoSkusAvailable": {
    "id": 215,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promotionNoSkusAvailable"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product has no available SKUs!"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promotionNoSkusAvailable"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/assets/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "productAdded": {
    "id": 216,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productAdded"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product added"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productAdded"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products-add/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "franchise": {
    "id": 217,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["franchise"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franchise"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["franchise"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products-add/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "noProductsFound": {
    "id": 218,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noProductsFound"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any products yet. Start creating a product with the Add Product button."])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noProductsFound"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "display": {
    "id": 219,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["display"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["display"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "productType": {
    "id": 220,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productType"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Type"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productType"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "productStatus": {
    "id": 221,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productStatus"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Status"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productStatus"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "connection": {
    "id": 222,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connection"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connection"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "releaseState": {
    "id": 223,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["releaseState"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release State"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["releaseState"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "developer": {
    "id": 224,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["developer"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["developer"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "productGUID": {
    "id": 225,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productGUID"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProductGUID"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productGUID"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products-edit/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "productID": {
    "id": 226,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productID"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProductID"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productID"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products-edit/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "connected": {
    "id": 227,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connected"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connected"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products-edit/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "status": {
    "id": 228,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products-edit/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "delistedDate": {
    "id": 229,
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delistedDate"])},
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delisted Date"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delistedDate"])},
    "term_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/my_vault/products-edit/"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "fuzzy": 0
  },
  "imageContainer": {
    "icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more"])},
    "packshots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packshots"])}
  },
  "inbox": {
    "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
    "outbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbox"])}
  },
  "inboxAssets": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "acceptanceSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptance"])},
    "acceptInboxSuccesfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Offer succesful"])},
    "acceptOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCEPT OFFER"])},
    "allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALLOCATION"])},
    "confirmFinalAcceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hereby accept this offer and understand that this is a permanent action and cannot be undone"])},
    "currentAvailableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Available"])},
    "dateRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "descriptionRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "fencingRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fencing"])},
    "fromRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "isAgreed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that this acceptance is final and cannot be undone"])},
    "maxQuantityTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Quantity"])},
    "maxStockTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Stock"])},
    "minBatchSizeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Batch Size"])},
    "NameRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "offerSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
    "offerTypeRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer type"])},
    "platformRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform (DRM)"])},
    "productNameRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
    "productSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "productTypeRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Type"])},
    "quantityRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "regionsRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region(s)"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "rejectInboxSuccesfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Offer succesful"])},
    "rightSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])},
    "selectProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Product"])},
    "validThroughRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Dates"])},
    "regionRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSFER"])},
    "estPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est. Price"])},
    "euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
    "unitprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Price"])}
  },
  "insertAssets": {
    "addMoreProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more products"])},
    "addNewRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new rights"])},
    "assetsAreBeingAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INVENTORY IS BEING ADDED..."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "descriptionForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "emptyFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File is empty."])},
    "error406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some or all uploaded keys already exist for this product, use the copy to clipboard button to get the details."])},
    "errorFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while reading the file."])},
    "extractionOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name: "])},
    "fileRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File is required."])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files: "])},
    "geoFencing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geo Fencing"])},
    "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INSERT"])},
    "insertAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INSERT INVENTORY"])},
    "insertSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert"])},
    "keyNotUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keys are not unique."])},
    "mustBeTxtFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected files must be .txt files"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
    "platformSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform: "])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "productSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product: "])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
    "rightsSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights: "])},
    "sameKeyAtDifferentFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are same key values in different txt files."])},
    "saveAsDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as draft"])},
    "selectOrDropTxtFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or drop a .txt file with your product code"])},
    "selectTxtFile1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or drop .txt file with your product code"])},
    "selectTxtFile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure that the selected file meets the following requirements"])},
    "stockAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The stock batch of test Air agreements is currently being processed. You will being updated via your inbox when the batch is fully processed."])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "summaryText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll get an update in your inbox when its fully processed."])},
    "summaryText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" In the mean while you can add extra units"])},
    "supportsBlackListing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisting"])},
    "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units: "])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "versionSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version: "])},
    "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
    "preOrderCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preorder Count"])},
    "serialCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Count"])},
    "reservationCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Count"])},
    "setPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Price: "])},
    "choosenCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosen Currency: "])},
    "regionProductDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region: "])},
    "platformProductDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform: "])},
    "skuProductDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU: "])},
    "stockPoolSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockpool"])},
    "stockPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STOCKPOOL"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "selectConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Connection"])},
    "maxStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Stock"])},
    "maxQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Quantity"])},
    "alertAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert at"])},
    "addConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Connection"])},
    "maxStockLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Stock(Limit): "])},
    "maxStockLimitText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximal amount allowed stock limit in the Receivers vault, for this product/SKU, Stock amount should be lower than the Max. Stock(Limit) in order to receive new/more stock via this allocation."])},
    "maxTotalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max.(Total) Quantity: "])},
    "maxTotalQuantityText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the maximum amount of stock for this connection."])},
    "createStockPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Stockpool"])},
    "warnPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert when below"])},
    "noStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no stock for this product"])},
    "connectionOccurs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection already selected"])},
    "stockPoolCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StockPool created"])},
    "enableDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Date Selection"])}
  },
  "keyprovidersAdd": {
    "addNewKeyProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new key provider"])}
  },
  "keyProvidersEdit": {
    "editEditKeyProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit key provider"])}
  },
  "login": {
    "createAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "rePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter Password"])},
    "screenResolutionText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCREEN RESOLUTION CURRENTLY NOT SUPORTED"])},
    "screenResolutionText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This version of VAULTN currently only supports desktop and laptop"])},
    "screenResolutionText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["screen resolutions from 1280px and up."])},
    "signInSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in successful"])},
    "signInUnSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in unsuccessful"])},
    "signInWithYourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with your email"])},
    "takeBackControlOfYourDigitalAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where the games industry transacts"])}
  },
  "authenticate": {
    "logInWithYourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with your email"])},
    "youNeedtoAuthenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to authenticate!"])},
    "twoFactorAuthentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-Factor Authentication"])},
    "weNeedToKnowThisIsReallyYourVault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We need to know this is really your vault"])},
    "letsMakeSureItsReallyYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's Make Sure It's Really You"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code"])},
    "code1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code 01"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code 02"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "enter6DigitCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the 6-digit code from your two-factor authentication app."])},
    "enter6DigitCodeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the 6-digit code sent to your email."])},
    "useEmailConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use e-mail confirmation instead"])},
    "use2FAConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use two factor confirmation instead"])},
    "enterTheCodeDisplayedInAuthenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter code displayed in the authenticator"])},
    "authenticatorApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticator App"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "sendTwoFactorAuthenticationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Two-Factor Authentication Code"])},
    "theCodeHasBeenSentViaemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code has been sent via email. You can request a new one after 50 seconds."])},
    "authenticationCodeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To connect a two-factor authentication app to your Vaultn account, please first log in using email verification. Then, navigate to your account settings and follow the provided instructions to link the app to your account"])},
    "followUpLabelEmail1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code has been sent via email."])},
    "followUpLabelEmail2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can request a new one after"])},
    "followUpLabelEmail3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds."])}
  },
  "mailTemplatesAdd": {
    "addNewMailTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new mail template"])}
  },
  "mailTemplatesEdit": {
    "editMailTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit mail template"])}
  },
  "markets": {
    "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MARKETS"])},
    "marketSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MARKET SETTINGS"])},
    "marketSettingsSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Settings"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "regionManagementInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activating and managing your Regions click on the 'Region Management' button"])}
  },
  "outboxAssets": {
    "allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSFER"])},
    "blacklistingRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisting:"])},
    "dateRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date:"])},
    "descriptionRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description:"])},
    "extractionRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction"])},
    "geoFencingRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geo Fencing:"])},
    "nameRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFFER"])},
    "platformRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform (DRM):"])},
    "quantityRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity:"])},
    "relationShipRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship:"])},
    "revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke"])},
    "revokeOutboxSuccesfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke Offer Succesfull"])},
    "typeRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type:"])},
    "vaultRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vault:"])},
    "visibilityRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])}
  },
  "pageContent": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "contentEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Content Edit"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
  },
  "pageHeaders": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "accountEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Edit"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "agreementflowTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreementflow Transaction"])},
    "agreements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
    "allocated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocated"])},
    "apiInsights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Insights"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory"])},
    "blacklistings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklistings"])},
    "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connections"])},
    "countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
    "exchangeRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange Rates"])},
    "Finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "generalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Settings"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
    "jitEndpoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JIT Endpoints"])},
    "keyProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Providers"])},
    "mailTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Templates"])},
    "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markets"])},
    "myAllocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Allocations"])},
    "myNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Network"])},
    "myVault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Vault"])},
    "outbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbox"])},
    "pageContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Content"])},
    "priceLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Lists"])},
    "productEditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Editions"])},
    "productFranchises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Franchises"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Catalog"])},
    "productTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Types"])},
    "promised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocated"])},
    "promises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Allocations"])},
    "promotions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])},
    "regions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RegionCodes"])},
    "relationships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationships"])},
    "reportList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report List"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "vaultnInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaultn Invitations"])},
    "vaultnManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaultn Management"])},
    "webhooks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])},
    "stockpool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockpool"])},
    "uploads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploads"])}
  },
  "priceLists": {
    "addNewPriceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new price list"])},
    "addPriceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Price List"])},
    "chooseXLXS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Price List Excel"])},
    "editingInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDITING MODE: Currently you are editing this Price List. The changes you make can't be undone after SAVING this page."])},
    "editPriceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Price List"])},
    "priceListDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price List Details"])},
    "excelFieldsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productId, title, regionCode, CountryCode, currencyCode, amount fields can not be empty. Check excel file row: "])},
    "listAllProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List All Products"])},
    "showOnlyWithPrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Only With Prices"])},
    "productIdMustBeInteger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productId must be a whole number. Check excel file row: "])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "connectionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
    "promotions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "pricingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing Type"])},
    "sourceMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Margin"])},
    "targetMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Margin"])},
    "priceListAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list added"])},
    "priceListCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list copied"])},
    "priceListDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list deleted"])},
    "priceListSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list saved"])},
    "priceListContinueSaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list continue saving. Please wait..."])},
    "priceListContinueSavingText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list continue saving "])},
    "priceListContinueSavingText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Please wait..."])},
    "ownerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Name"])},
    "addProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product Price"])},
    "selectProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Product"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "defaultCurrencySmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Currency"])},
    "srp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SRP"])},
    "wsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WSP"])},
    "fallBackPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallback price"])},
    "defaultPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Price"])},
    "addProductPriceInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add more countries and currencies to a region of any product."])},
    "priceListRegionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricelist region added"])},
    "showAllCountries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW ALL COUNTRIES"])},
    "hideAllCountries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIDE ALL COUNTRIES"])},
    "setaDefaultPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SET A DEFAULT PRICE"])},
    "cannotBeSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot be sold to the following countries:"])},
    "chooseConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a connection to view/edit cost price"])},
    "selectDefaultCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CURRENCY"])},
    "selectDefaultCurrencySmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "regionUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region Updated"])},
    "regionDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region Deleted"])},
    "defaultCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEFAULT CURRENCY"])},
    "priceSRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRICE (SRP)"])},
    "costWSP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COST (WSP)"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "defaultPriceUpper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPLY TO ALL"])},
    "selectCountryToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Country"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "selectedCountriesAreAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected country is added"])},
    "AddToAllCountries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add (to) all Countries"])},
    "priceListIsOwnedByYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Pricelist is Owned by you"])},
    "priceListIsSharedToYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This PriceList is shared to you by "])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "duplicatePricelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Pricelist"])},
    "copyPriceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Price List"])},
    "copyOf1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy of < "])},
    "copyOf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" >"])}
  },
  "setDefaultPrice": {
    "setDefaultPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SET DEFAULT PRICE"])},
    "PriceLists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Lists"])},
    "setDefaultPriceSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Default Price"])},
    "selectCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Currency"])},
    "srp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price (SRP)"])},
    "wsp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost (WSP)"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
  },
  "productEditionsAdd": {
    "addNewProductEdition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new product edition"])}
  },
  "productEditionsEdit": {
    "editProductEdition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit product edition"])}
  },
  "productFranchisesAdd": {
    "addNewProductFranchise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new product franchise"])}
  },
  "productFranchisesEdit": {
    "editProductFranchise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit product franchise"])}
  },
  "products": {
    "addProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Products"])},
    "productAddSuccesfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product add successful"])},
    "productAddUnSuccesfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product add unsuccessful"])},
    "productConnectedStatusUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Connected Status Updated"])}
  },
  "productsAdd": {
    "addNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new product"])},
    "addRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Row"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "downloadPriceListExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Price List Excel"])},
    "downloadPromotionsListExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Promotions List Excel"])},
    "editProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit product"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "inputType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input type + field"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "metaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata"])},
    "metaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "metaValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "productImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Image"])},
    "reportDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Duplicate"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "developers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])},
    "protectionSystems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection Systems"])},
    "releaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release Date"])},
    "serialReleaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Release Date"])},
    "digitalReleaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Release Date"])},
    "genres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genres"])},
    "spokenLanguages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoken Languages"])},
    "subtitleLanguages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtitle Languages"])},
    "menuLanguages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Languages"])},
    "whiteListCountries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White List Countries"])},
    "regionCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region Codes"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
    "minimumRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Requirements"])},
    "recommendedRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended Requirements"])},
    "availableForPreOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available For Preorder"])},
    "productActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Activation"])},
    "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Image"])},
    "pleaseSelectAnImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an image"])},
    "imageUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image uploaded"])},
    "createRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Region"])}
  },
  "productTypes": {
    "addNewType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD NEW TYPE"])},
    "editType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDIT TYPE"])},
    "editTypeSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Type"])},
    "newType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Type"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
  },
  "promiseAssets": {
    "assetsAreBeingTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASSETS ARE BEING TRANSFERRED"])},
    "assetsSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets: "])},
    "createPromise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATE ALLOCATION"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order: "])},
    "promise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALLOCATION"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "toSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To: "])},
    "transferSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer: "])},
    "unitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit price"])}
  },
  "promisedAssets": {
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
    "availableRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available:"])},
    "expiresRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires:"])},
    "maxQuantityRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Quantity:"])},
    "maxStockRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Stock:"])},
    "minBatchRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Batch:"])},
    "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
    "pausedRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paused:"])},
    "platformRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform (DRM):"])},
    "productRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product:"])},
    "promised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Allocations"])},
    "promisedSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Allocations"])},
    "promisedToMyVault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY ALLOCATIONS"])},
    "relationShipRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship:"])},
    "rightRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right:"])},
    "startsRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts:"])}
  },
  "promisesAssets": {
    "activeRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active:"])},
    "availableRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available:"])},
    "expiresRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires:"])},
    "maxQuantityRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Quantity:"])},
    "maxStockRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Stock:"])},
    "minBatchRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Batch:"])},
    "myPromise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALLOCATED"])},
    "myPromiseSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocated"])},
    "pausedByRelationRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paused By Relation:"])},
    "pausedRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paused:"])},
    "platformRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform (DRM):"])},
    "productRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product:"])},
    "promises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocated"])},
    "relationShipRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship:"])},
    "remoteStockRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Stock:"])},
    "rightRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right:"])},
    "startsRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts:"])}
  },
  "promotionFinance": {
    "editPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDIT PROMOTION"])},
    "promotions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROMOTIONS"])},
    "promotionsSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])}
  },
  "promotions": {
    "addNewPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new Promotion"])},
    "addPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Promotion"])},
    "chooseXLXS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Promotions List Excel"])},
    "discountFieldValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid number in the Discount Field."])},
    "editPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Promotion"])},
    "promotionDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion Details"])},
    "excelFieldsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id, promotionID, product title, sku, discount fields can not be empty. Check excel file row: "])},
    "idMustBeInteger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["id must be integer. Check excel file row: "])},
    "promotionIDMustBeInteger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promotionID must be a whole number. Check excel file row: "])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid From"])},
    "validFromToCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid To date must be greater than Valid From date"])},
    "validTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid To"])},
    "promotionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion added"])},
    "promotionDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion deleted"])},
    "deletePromotionItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Promotion Items"])},
    "promotionItemsDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion items deleted"])},
    "promotionSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion saved"])},
    "productSkusAreAddedAsPromotionItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product SKUs are added as promotion items"])},
    "productSkusAreAddedAreGoingToBeAddedPromotionItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product SKUs are going to be added as promotion items"])},
    "ownerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Name"])},
    "linkedPriceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked Pricelist"])},
    "allDatesUTCFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All dates are formatted to UTC format, after SAVING"])},
    "changedDatesAreFormattedToUTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed dates are formatted to UTC format, after SAVING"])},
    "promotionIsOwnedByYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This promotion is Owned by you"])},
    "promotionIsSharedToYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This promotion is shared to you by "])},
    "selectSinglePromotionItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Single Promotion Item"])},
    "selectProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Products"])},
    "AddSinglePromotionItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Single Promotion Item"])},
    "AddAllPromotionItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add All Promotion Items"])},
    "PromotionItemAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion item added"])},
    "pleaseSelectPromotionItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select promotion item"])},
    "addPromotionItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Promotion Item"])},
    "allDatesUTCFormatInitialEntries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial entries are auto-converted to UTC from your local time. During editing, all times are displayed in UTC. Please verify your entries after saving for accuracy."])},
    "duplicatePromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Promotion"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "copyPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Promotion"])},
    "copyOf1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy of < "])},
    "copyOf2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" >"])},
    "promotionCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion copied"])},
    "addAllProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add All Products"])},
    "addToPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Promotion"])},
    "addAllSkus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add All SKUs"])},
    "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
    "addItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Items"])},
    "pleaseSelectSkuFromListToAddPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select SKU from list to add promotion"])}
  },
  "relationships": {
    "acceptRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Connection"])},
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
    "activateRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIVATE CONNECTION"])},
    "activeSinceRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Since:"])},
    "addRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite New Connection"])},
    "cancelRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Connection"])},
    "companyImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Image:"])},
    "companyRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company:"])},
    "deleteRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Connection"])},
    "removePricingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Pricing Type"])},
    "emailAddressRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact email address:"])},
    "emailFromRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email From:"])},
    "emailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email To"])},
    "fromRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From:"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited"])},
    "invitedRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INVITED FOR CONNECTION"])},
    "inviteGUID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite GUID"])},
    "inviteNewRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INVITE NEW CONNECTION"])},
    "ownerEmailRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner email:"])},
    "ownerNameRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Name:"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "pendingRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PENDING CONNECTION"])},
    "reInviteRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-invite Connection"])},
    "rejectRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Connection"])},
    "relationGUID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation GUID"])},
    "relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
    "relationships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connections"])},
    "relationshipStatusRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection Status:"])},
    "saveRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Connection"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "vaultRowTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vault:"])},
    "selectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Connection Type"])},
    "blacklistedCountries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisted Countries"])},
    "restrictedCountries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricted Countries"])},
    "defaultPricelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Pricelist"])},
    "catalogSharing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog Sharing"])},
    "emailToDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email To"])},
    "pricingModelTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing Type"])},
    "sourceMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Margin"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "invoicingCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoicing Currency"])},
    "targetMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Margin"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail Address"])},
    "connectionReInviteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection re-invitation successfully sent!"])},
    "connectionCancelMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection canceled successfully."])},
    "connectionInviteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection invitation successfully sent!"])}
  },
  "reportDuplicate": {
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "reportDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REPORT DUPLICATE"])},
    "reportDuplicateSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Duplicate"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
  },
  "reports": {
    "availableReportTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Report Types"])},
    "createaReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Report"])},
    "createReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATE REPORT"])},
    "createReportBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATE REPORT"])},
    "DateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Range"])},
    "directionTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction Types"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "keyByKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key By Key"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REPORTS"])},
    "selectReportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Type"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "validFromToCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date must be greater than Start date"])},
    "selectConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Connection"])},
    "T2FinanceReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T2 Finance Report"])}
  },
  "resetPassword": {
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter your email address below and we'll send you instructions on how to reset your password."])},
    "instructionsRegister1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password needs to be (re)applied."])},
    "instructionsRegister2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply a minimum of 8 characters in length which should contain one of every character type: Uppercase, lowercase, number & symbol."])},
    "instructionsRegister3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must consider the following conditions"])},
    "mailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password instructions have been sent to the provided email address"])},
    "mailSentUnSuccessfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldnt sent instructions to your email"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "resetMyPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset my password"])},
    "returnToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to login"])},
    "saveAndLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & Login"])},
    "8Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 Characters"])},
    "ContainsNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains Number"])},
    "ContainsUpperCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains UpperCase"])},
    "ContainsLowerCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains LowerCase"])},
    "ContainsSpecialCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains Special Character"])}
  },
  "sendTestEmail": {
    "sendTestEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEND TEST EMAIL"])},
    "sendTestEmailSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Test email"])}
  },
  "setPassword": {
    "passwordCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account created successfuly. Please login with your new credentials."])},
    "passwordsMustBeSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password & Re-enter Password don't match. Please check entered fields"])},
    "passwordValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be minimum of 8 characters in length which should contain one of every character type: Uppercase, lowercase, number & symbol."])}
  },
  "sku": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
    "copySKUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy SKUs"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
  },
  "summaryCard": {
    "stockPoolName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StockPool"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filename"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "relationShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
    "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Units"])}
  },
  "table": {
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
    "noSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SKU"])}
  },
  "tablePager": {
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "extractAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extract Inventory"])},
    "insertAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Inventory"])},
    "transferAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Inventory"])},
    "editStockPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit StockPool"])},
    "editRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Region"])},
    "setAsFallBackPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as Fallback Price"])},
    "setADefaultPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a Default Price"])},
    "addCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Country"])},
    "deleteRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Region"])}
  },
  "transactions": {
    "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "dateTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
    "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Period"])},
    "selectStartDateEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select Start Date & End Date"])}
  },
  "transferAssets": {
    "allocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "assetDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Details"])},
    "assetsSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost: "])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "extractionOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction"])},
    "geoFencing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geo Fencing"])},
    "inEuro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in EURO (€)"])},
    "maxQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Quantity"])},
    "maxStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Stock"])},
    "maxStockMustbebiggerThanMinBatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum stock must be bigger than minimum batch size"])},
    "minBatchSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Batch Size"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "noDateSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No date set"])},
    "orderNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order No"])},
    "orderNoCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order No: "])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
    "orderSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order: "])},
    "platformSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform (DRM)"])},
    "platformSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform: "])},
    "productSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "productSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product: "])},
    "promotionalDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotional Dates"])},
    "regionCodeSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "relationShipSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
    "relationshipSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship: "])},
    "rightsSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
    "rightsSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights: "])},
    "selectAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Assets"])},
    "selectProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Products"])},
    "selectRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "selectRelationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Connection"])},
    "selectRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Rights"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell: "])},
    "st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" st."])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "summarySmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "supportsBlackListing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blacklisting"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "toSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To: "])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total: "])},
    "totalAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Assets"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSFER"])},
    "transferAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSFER INVENTORY"])},
    "transferNewAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer new Assets"])},
    "transferring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSFERRING..."])},
    "transferSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "transferStockSuccesfull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Stock Succesfull"])},
    "transferSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll get an update in your inbox when it's fully processed. In the mean while you can transfer extra assets"])},
    "transferType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Type"])},
    "transferTypeSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Type"])},
    "unitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit price"])},
    "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
    "unitsAndPricingSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units & Pricing"])},
    "unitSellPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit sell price "])},
    "unitsSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units: "])},
    "untisAreBeingTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNITS ARE BEING TRANSFERRED"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "validityForPromise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity (For Allocation)"])},
    "validTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "valueCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value: "])},
    "valueSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value: "])},
    "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
    "priceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricelist:"])},
    "unitPriceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Price:"])},
    "estValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est. Value:"])},
    "vaultNName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaultn Name:"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name:"])},
    "productTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title:"])},
    "skuSegment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU:"])},
    "validFromEstimated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid From:"])},
    "validToEstimated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid To:"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount: "])},
    "discountedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounted Value: "])},
    "discountedTotalValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounted Total Value: "])}
  },
  "userEdit": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOUNT"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDIT USER"])},
    "editUserSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW USER"])},
    "newUserSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Information"])},
    "2FAAUthentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Authentication"])},
    "reSendInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Invite"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saveAndInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & Invite"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "receiveEmailNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive e-mail notifications"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "userInvited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Invited"])}
  },
  "validationMessages": {
    "enterValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email"])},
    "maxChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check maximum characters"])},
    "maxValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check maximum value"])},
    "minChars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check minimum characters"])},
    "minValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check minimum value"])},
    "mustBeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be numeric"])},
    "mustBeString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be string value"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Field"])}
  },
  "vaultnInvitations": {
    "invitationSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation sent"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "inviteCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite new company"])},
    "inviteNewCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INVITE NEW COMPANY"])}
  },
  "vaultnManagement": {
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaultn Management Detail"])}
  },
  "webhookAdd": {
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
    "generateJWT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate JWT"])},
    "newWebhookSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Webhook"])},
    "PleaseEnterEndpointUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter endpoint URl"])},
    "webhookConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEBHOOK CONFIGURATION"])}
  },
  "webhookEdit": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOUNT"])},
    "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
    "configureWebhookSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure Webhook"])},
    "editWebhookSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Webhook"])},
    "generateJWT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate JWT"])},
    "webhookConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEBHOOK CONFIGURATION"])}
  },
  "widget": {
    "allocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocations"])},
    "bestAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Assets"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "inOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In / Out"])},
    "invoiced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoiced"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Week"])},
    "lowStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low Stock"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Margin:"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "onGoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "productCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Catalog"])},
    "promises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocations:"])},
    "promisesIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocations IN"])},
    "promisesInOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocations In / Out"])},
    "promisesOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocations OUT"])},
    "revenueInOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue IN / OUT"])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Value"])},
    "stockValueInOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Value IN / OUT"])},
    "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier"])},
    "tabIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IN"])},
    "tabOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUT"])},
    "totalAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL ASSETS:"])},
    "totalMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Margin"])},
    "totalStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL STOCK"])},
    "totalUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL UNITS:"])},
    "transferIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer IN"])},
    "transferOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer OUT"])},
    "transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers"])},
    "units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALUE:"])},
    "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Links"])},
    "myVault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Vault"])},
    "myVaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your inventory and track transaction progress with ease."])},
    "financeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take command of price lists, promotions, and generate tailored reports to suit your requirements."])},
    "myNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Network"])},
    "myNetworkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand your network by effortlessly adding new partners or efficiently manage your existing ones."])},
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])},
    "accountSettingsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to all settings relating to your Vaultn account."])}
  },
  "settings": {
    "updateOwnerRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Owner Role"])}
  },
  "JITEndPoints": {
    "allowBackOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Back Order"])},
    "reservationExpiryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Expiry Time"])}
  },
  "stockPool": {
    "stockPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STOCKPOOL"])},
    "stockPoolSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StockPool"])},
    "editStockPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDIT STOCKPOOL"])},
    "selectStockPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select StockPool"])},
    "selectStockPoolSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select StockPool"])},
    "editStockPoolSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit StockPool"])},
    "stockPoolSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StockPool saved"])},
    "fromToCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To date must be greater than From date"])},
    "pool#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool#"])},
    "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connections:"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates:"])},
    "totalStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Stock:"])},
    "restStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Stock:"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product:"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform:"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region:"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right:"])},
    "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NA"])}
  },
  "regions": {
    "addRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add RegionCode"])},
    "showActiveRegions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Active Regions"])},
    "showAllSystemRegions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show All System Regions"])},
    "showExcludedRegions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Excluded Regions"])},
    "searchProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Product"])},
    "notAllowedToEditSystemRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not allowed to update SYSTEM REGION"])},
    "notAllowedToEditActiveRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not allowed to update ACTIVE/IN USE REGION"])},
    "regionCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RegionCodes"])},
    "regionManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region Management"])},
    "notTheOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not the owner of this region"])}
  },
  "regionsAdd": {
    "addNewRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New RegionCode"])},
    "regionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region Name"])},
    "regionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region template"])},
    "addCountries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Countries"])},
    "shortCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short Code"])},
    "infoText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This region will be part of your custom regions."])},
    "infoText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a copy of"])},
    "createACopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a copy"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region Name, Short Code can not be empty."])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluded"])},
    "regionCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region created"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "enterRegionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Region Name"])},
    "createRegionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create RegionCode"])}
  },
  "regionsEdit": {
    "editRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit RegionCode"])},
    "regionCountries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region Countries"])},
    "regionUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region Updated"])},
    "infoText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a Default System Region"])},
    "infoText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a copy of"])},
    "infoText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This region is ACTIVE in use and cannot be deleted"])},
    "infoText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not the owner of this region"])},
    "infoText5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESTRICTED: Cannot be activated in the following countries:"])},
    "regionDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region deleted"])},
    "regionWillBeDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region will be deleted. Are you sure?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "regionCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region Copied"])},
    "copyRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy RegionCode"])},
    "viewRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View RegionCode"])}
  }
}